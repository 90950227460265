<template>
  <div 
    class="fixed bottom-0 left-0 w-full h-20 flex items-center border-t border-dashed border-grey-light-300"
    :class="classes"
  >
    <slot/>
  </div>
</template>

<script>
  export default {
    props: [ 'classes' ]
  }
</script>

<style lang="scss" scoped>

</style>